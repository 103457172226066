export default {
    "text": {
        "showName": "Текст",
        "templatesFolder": "componentsCompany/demo/elementScales/text"
    },
    "number": {
        "showName": "Число",
        "templatesFolder": "componentsCompany/demo/elementScales/number"
    },
    "date": {
        "showName": "Дата",
        "templatesFolder": "componentsCompany/demo/elementScales/date"
    }
}