export default {
    "sections": {
        "kpi": {
            "showName": "Библиотечные цели. Факт загружается автоматически",
            "fromLibrary": "kpi",
            "hideOnStagesIfEmpty": [
                "assessment",
                ""
            ],
            "emptyText": "Нет целей. Ты можешь добавить библиотечный показатель или оставить эту секцию пустой, заполнив \"Прочие цели\"",
            "fieldsAvailableForEdit": {
                "weight": {
                    "stages": [
                        "plan"
                    ]
                }
            }
        },
        "kpi_manual_fact": {
            "showName": "Библиотечные цели. Факт вносится самостоятельно",
            "fromLibrary": "kpi_manual_fact",
            "hideOnStagesIfEmpty": [
                "assessment",
                ""
            ],
            "emptyText": "Нет целей. Ты можешь добавить библиотечный показатель или оставить эту секцию пустой, заполнив \"Прочие цели\"",
            "fieldsAvailableForEdit": {
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ]
                }
            }
        },
        "mbo": {
            "showName": "Прочие цели. Редактируются самостоятельно",
            "hideOnStagesIfEmpty": [
                "assessment",
                ""
            ],
            "emptyText": "Нет целей. Ты можешь внести цели самостоятельно или оставить эту секцию пустой, выбрав библиотечные цели",
            "fieldsAvailableForEdit": {
                "group": {
                    "stages": [
                        "plan"
                    ]
                },
                "name": {
                    "stages": [
                        "plan"
                    ]
                },
                "desc": {
                    "stages": [
                        "plan"
                    ]
                },
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale_type": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.unit": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ]
                }
            }
        }
    },
    "sectionsBlockHeaderTemplate": "componentsCompany/demo/CardSectionsBlockHeader"
}