<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="70%"
    >
      <el-row>
        <report-result
          ref="reportResultTable"
          :builder-fields="builderFields"
          :builder-relations="builderRelations"
          :builder-search="builderSearch"
        ></report-result>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
      </div>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import Paginator from "@/components/Paginator.vue";
import ReportResult from "@/components/report/builder/ReportResult.vue";

export default {
  name: "mass-action-report-result-modal",
  components: {ReportResult, Paginator},
  props: {
    rootNode:{},
    builderRelations:{},
    builderSearch:{},
  },

  data() {
    return {
      show: false,
      builderFields:[],
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;

      // Заполняем поля из конфига для колонок в превью
      let previewFields = this.$companyConfiguration.massActions.getReportBuilderEntityPreviewSettings(this.rootNode.key);
      this.builderFields = previewFields.selected_fields;

      this.$nextTick(function () {
        this.$refs.reportResultTable.loadReportData(1);
      })
    }
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }

}
</script>

<style>

</style>