const getInputElement = (element) => {
    if (element.tagName.toLocaleUpperCase() === 'INPUT') {
        return element;
    }

    const inputElements = element.getElementsByTagName('input');
    if (inputElements.length === 0) {
        throw new Error('[vue-directive-mask]: v-mask directive requires input');
    }

    return inputElements[0];
};

const updateValue = (element, params) => {
    const oldValue = element.value;
    const newValue = format(element.value, params);

    if( oldValue !== newValue ){
        element.value = format(element.value, params);
        element.dispatchEvent(new Event("input", {"bubbles":true, "cancelable":true}));
    }
};

const format = (value, params) => {
    value = value.replace(/[a-zA-Zа-яА-Я]/g, '');

    const decimalLength = params && params.decimals ? params.decimals : 2;
    const decimalPoint = params && params.point ? params.point : ',';

    value = value.replace(/\.|,/gi, decimalPoint);
    let parts = value.split(decimalPoint);
    if( parts[1] ){ // Есть дробная часть
        if( parts[1].length > decimalLength ){
            value = parts[0] + decimalPoint + parts[1].substring(0, decimalLength);
        }
    }

    return value;
};


export const NumberFilter = (value, params) => {
    if( value === null ){
        return '';
    }

    value = value.toLocaleString('ru-RU', {
        maximumFractionDigits: 20,
    });

    value = value.replace('.', ',');

    return value;
}



export default {
    bind(element, binding) {
        if( !binding.value ){
            return;
        }
        const inputElement = getInputElement(element);
        updateValue(inputElement, binding.value);
    },
    componentUpdated(element, binding) {
        if( !binding.value ){
            return;
        }
        const inputElement = getInputElement(element);
        updateValue(inputElement, binding.value);
    },
    inserted(element, binding){
        if( !binding.value ){
            return;
        }
        const inputElement = getInputElement(element);
        updateValue(inputElement, binding.value);
    }
}