import axiosInstance from "./axiosInstance";
import router from '@/router'
import { Notification } from 'element-ui';

const requestSender = (method, url, payload, options= {}) => {
    method = method.toLowerCase();
    if( method === 'get' ){
        payload = {params:payload};
    }
    return new Promise((resolve, reject) => {
        axiosInstance[method](url, payload, options)
            .then((response) => {

                if( options && options.responseType && options.responseType === 'blob' ){
                    resolve({
                        data: response.data,
                        headers: response.headers,
                    });
                }

                if(response.data._paginator || response.data._sorter){
                    resolve({
                        data: response.data,
                        paginator: response.data._paginator,
                        sorter: response.data._sorter
                    });
                } else {
                    resolve(response.data);
                }
            })
            .catch(e => {
                processError(e.response);
                reject(e.response);
            })
    });

}

function processError(response){
    const status = response ? response.status : false;
    const originalRequest = response.config;

    // console.log(status);
    // console.log(originalRequest);


    if( status === 404 ){
        if( router.currentRoute.name === '404' ){
            return;
        }
        router.push({ name: '404'});
        return;
    }

    if( status === 401 ){
        window.location.href = '/login';
    }

    let message = 'Что-то пошло не так';
    if( response && response.data ){
        if( response.data.message ){
            message = response.data.message;
        } else {
            message = parseErrorsForMessage(response.data.errors);
        }
    }
    Notification.error({
        title: 'Ошибка',
        message: message,
        dangerouslyUseHTMLString: true
    });
}

function parseErrorsForMessage(errors){
    let message = '';
    if( errors instanceof Array ){
        message = '';
        errors.forEach(error => {
            if( error instanceof Object ){
                message += '<div>' + error.error + '</div>';
            } else {
                if( !error ) return;
                message += '<div>' + error + '</div>';
            }
        })
    } else if( errors instanceof Object ){
        message = '';
        for (const [key, value] of Object.entries(errors)) {
            errors[key].forEach(error => {
                if( !error ) return;
                message += '<div>' + error + '</div>';
            })
        }
    }
    console.log(message);
    if( !message.length ){
        message = 'Что-то пошло не так';
    }
    return message;
}


export default requestSender