import { render, staticRenderFns } from "./LibraryFolders.vue?vue&type=template&id=487d3623&scoped=true&"
import script from "./LibraryFolders.vue?vue&type=script&lang=js&"
export * from "./LibraryFolders.vue?vue&type=script&lang=js&"
import style0 from "./LibraryFolders.vue?vue&type=style&index=0&id=487d3623&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487d3623",
  null
  
)

export default component.exports