export const paginator = {
    data() {
        return {
            paginator: {
                param: 'page',
                paramPerPage: 'per-page',

                page: 1,
                // 'perPage': 2,

                currentPage: null,
                pageCount: null,
                perPage: 10,
                totalCount: null,

                perPageSizes: [10,25,50,100],
            },
        }
    },
    methods: {
        paginatorFill(paginator){
            this.paginator.currentPage = paginator.currentPage;
            this.paginator.pageCount = paginator.pageCount;
            this.paginator.perPage = paginator.perPage;
            this.paginator.totalCount = paginator.totalCount;
        },

    }
}