import axiosInstance from "./axiosInstance";
import authModule from '../auth'
import rbacModule from '../rbac'
import userModule from '../user'
import libraryModule from '../library'
import homeModule from "../home";
import cardModule from "../card";
import commentModule from "../comment";
import reportModule from "../report";
import massActionModule from "../massAction";
import groupModule from "../group";
import matrixModule from "../matrix";
import settingsModule from "../setting";
import notificationModule from "../notification";
import orgstructModule from "../orgstruct";

export default {
    auth: authModule(axiosInstance),
    rbac: rbacModule(axiosInstance),
    user: userModule(axiosInstance),
    library: libraryModule(axiosInstance),
    home: homeModule(axiosInstance),
    card: cardModule(axiosInstance),
    comment: commentModule(axiosInstance),
    report: reportModule(axiosInstance),
    massAction: massActionModule(axiosInstance),
    group: groupModule(axiosInstance),
    matrix: matrixModule(axiosInstance),
    settings: settingsModule(axiosInstance),
    notification: notificationModule(axiosInstance),
    orgstruct: orgstructModule(axiosInstance),
}