export default {
    "types": {
        "kpi": {
            "showName": "Библиотека KPI"
        },
        "kpi_manual_fact": {
            "showName": "Библиотека KPI с ручным фактом"
        }
    },
    "elements_table_columns": [
        {
            "label": "Наименование КПЭ",
            "template": "components/tableColumnTemplates/library/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "show-folders-in-search": "templateData.$parent.$parent.$data.showFoldersInSearch"
            }
        },
        {
            "label": "Единица измерения",
            "prop": "scale.unit"
        },
        {
            "label": "Настройки шкалы",
            "template": "components/tableColumnTemplates/library/LibraryElementScaleConfigTableColumn",
            "bind": {
                "scope": "scope"
            }
        }
    ]
}