<template>
  <div>
    <h2>{{ $companyConfiguration.user.getChiefTypeShowName(type) }}</h2>
    <el-row>
      <el-col :span="6">
        <el-input
          v-model="search"
          prefix-icon="el-icon-search"
          placeholder="Поиск"
          size="mini"
          @clear="getChiefs"
          clearable
          v-debounce:500="getChiefs"
        >
        </el-input>
      </el-col>
      <el-col :span="18" align="right">
        <add-users-dialog
          v-if="$canAndRulesPass(user, $permissions.USER.CHIEFS_SET)"
          single-select
          add-button-text="Нажмите на строку для выбора"
          @users-selected="addUser"
        >
          <el-button
            slot="reference"
            size="mini"
            type="primary"
            icon="fas fa-plus"
          >
            Добавить пользователя
          </el-button>
        </add-users-dialog>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      size="mini"
      empty-text="Нет пользователей"
      :data="chiefs"
      @sort-change="sortChanged($event, getChiefs)"
    >

      <component
        v-for="(columnConfig, index) in columnsConfigs"
        :is="columnConfig.manualColumnTemplate ? columnConfig.template : baseTableColumn"
        :key="index"
        :column-config="columnConfig"
        :sortable="setIsSortable(columnConfig.prop)"
      >
      </component>

      <el-table-column
        v-if="$canAndRulesPass(user, $permissions.USER.CHIEFS_SET)"
        width="240"
        align="right"
      >
        <template slot-scope="scope">
          <el-tooltip
            style="display: inline-block"
            content="Выбрать нового руководителя"
            placement="top"
            :open-delay="500"
          >
            <add-users-dialog
              single-select
              add-button-text="Нажмите на строку для выбора"
              @users-selected="setNewChief(scope.row, $event)"
            >
              <el-button
                slot="reference"
                class="table-action-button"
                round
                size="mini"
                icon="fas fa-person-booth"
              >
              </el-button>
            </add-users-dialog>
          </el-tooltip>
          <el-tooltip
            style="display: inline-block; margin-left:5px"
            content="Удалить"
            placement="top"
            :open-delay="500"
          >
            <el-button
              class="table-action-button"
              size="mini"
              round
              icon="fas fa-trash-alt"
              @click="deleteChief(scope.row)"
            >
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <paginator
      :paginator.sync="paginator"
      @page-changed="getChiefs"
    ></paginator>

  </div>
</template>

<script>
import AddUsersDialog from "./AddUsersDialog";
import Paginator from "../Paginator";
import ClickToEdit from "../ClickToEdit";
import {paginator} from "@/mixins/paginator";
import {sorter} from "@/mixins/sorter";
import {mapGetters} from "vuex";


export default {
  name: "chiefs-edit-component",
  components: {ClickToEdit, Paginator, AddUsersDialog},
  mixins: [paginator, sorter],

  props: {
    type: {type: String, required: true},
  },

  computed: {
    ...mapGetters(['user']),
    baseTableColumn() {
      return () => import(`@/components/tableColumnTemplates/BaseTableColumn`);
    },
  },
  data() {
    return {
      loading: false,
      search: null,
      columnsConfigs: [],

      chiefs: [],
    }
  },
  beforeMount() {
    this.columnsConfigs = this.$companyConfiguration.helpers.getTableColumnsArrayFromConfig(
      this.$companyConfiguration.user.getChiefsListColumns()
    );
  },
  mounted() {
    this.getChiefs();
  },
  watch: {},
  methods: {
    getChiefs(){
      this.loading = true;
      this.$api.orgstruct.getCustomChiefsByType({
        search: this.search,
        type: this.type,

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,

      })
        .then(({data, paginator, sorter}) => {
          this.sorterFill(sorter);
          this.paginatorFill(paginator);

          this.chiefs = data.chiefs;
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    addUser(user){
      this.chiefs.unshift({
        user: user,
        chief: {}
      });
    },

    setNewChief(row, newChief){
      this.saveNewChief(row.user.id, newChief.id);
    },
    deleteChief(row){
      this.saveNewChief(row.user.id);
    },

    saveNewChief(user_id, chief_id=null){
      this.loading = true;
      this.$api.orgstruct.setChief({
        user_id: user_id,
        chief_id: chief_id,
        type: this.type,
      })
        .then(() => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Руководитель успешно сохранен'
          });
          this.getChiefs();
        })
        .catch(()=>{
          this.loading = false;
        })
    },


  }
}
</script>

<style>

</style>