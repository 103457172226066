export default {
    "default_negotiations": [
        "self",
        "direct_chief",
        "functional_chief"
    ],
    "types": {
        "self": {
            "showName": "Сотрудник"
        },
        "direct_chief": {
            "showName": "Административный руководитель"
        },
        "functional_chief": {
            "showName": "Функциональный руководитель"
        },
        "other": {
            "showName": "Прочее"
        }
    }
}