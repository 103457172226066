<template>
  <div v-if="$canAndRulesPass(user, 'setting.scales')">
    <page-heading
      heading="Шкалы"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>


    <el-card>

      <scales-settings></scales-settings>
    </el-card>





  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import RbacTable from "@/components/settings/rbac/RbacTable";
import {mapGetters} from "vuex";
import ScalesSettings from "@/componentsCompany/tmk/scalesSettings/ScalesSettings.vue";
import ChiefsEditComponent from "@/components/user/ChiefsEditComponent.vue";

export default {
  name: 'Settings',
  components: {ScalesSettings, PageHeading},

  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {

    }
  },
  mounted() {},
  methods: {

  }
}

</script>


<style lang="scss">


</style>
