<template>
  <div>
    <el-row type="flex" justify="space-between" :style="toolRowStyle" style="margin-bottom: 12px;">
      <el-col :span="12">
        <el-input
          v-if="searchField"
          v-model="search"
          prefix-icon="el-icon-search"
          placeholder="Поиск"
          size="mini"
          @clear="searchUsers"
          clearable
          v-debounce:500="searchUsers"
        >
        </el-input>
      </el-col>
      <el-col :span="12" align="right">
        <add-users-dialog
          v-if="addButton"
          :filter-role="addUsersFilterRole"
          @users-selected="addUsers"
        >
          <el-button
            slot="reference"
            size="mini"
            type="text"
            icon="fas fa-plus"
          >
            {{ addButtonText }}
          </el-button>
        </add-users-dialog>
      </el-col>
    </el-row>

    <el-table
      v-loading="loading"
      size="mini"
      empty-text="Нет пользователей"
      :data="users"
    >

      <component
        v-for="(columnConfig, index) in columnsConfigs"
        :is="columnConfig.manualColumnTemplate ? columnConfig.template : baseTableColumn"
        :key="index"
        :column-config="columnConfig"
        :sortable="setIsSortable(columnConfig.prop)"
      >
      </component>

      <el-table-column align="right" width="50">
        <template v-slot="scope">
          <el-popconfirm
            v-if="deleteButton"
            confirm-button-text='Да'
            cancel-button-text='нет'
            icon="el-icon-question"
            :title="deleteUserText"
            @confirm="deleteUser(scope.row)"
          >
            <el-button
              slot="reference"
              class="table-action-button"
              round
              type="default"
              size="small"
              icon="el-icon-delete"
              :loading="loading"
            >
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import AddUsersDialog from "./AddUsersDialog";
import {sorter} from "@/mixins/sorter";


export default {
  name: "users-table",
  mixins: [sorter],
  components: {AddUsersDialog},

  props: {
    users: {
      type: Array,
      required: true,
    },
    searchField: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },

    addUsersFilterRole: {},

    addButton: {
      type: Boolean,
      default: true
    },
    addButtonText: {
      type: String,
      default: 'Добавить пользователей'
    },

    deleteButton: {
      type: Boolean,
      default: true
    },
    deleteUserText: {
      type: String,
      default: 'Вы уверены, что хотите удалить пользователя?'
    },
    toolRowStyle: {},

  },

  computed: {
    baseTableColumn() {
      return () => import(`@/components/tableColumnTemplates/BaseTableColumn`);
    },
  },
  data() {
    return {
      search: null,
      columnsConfigs: [],
    }
  },
  beforeMount() {
    this.columnsConfigs = this.$companyConfiguration.helpers.getTableColumnsArrayFromConfig(
      this.$companyConfiguration.user.getUsersListColumns()
    );
  },
  mounted() {},
  watch: {},
  methods: {
    addUsers(users) {
      let newUsers = [];
      let finalUsersArray = this.users;
      users.forEach(user => {
        if (this.users.find(val => val.id === user.id)) {
          return;
        }
        finalUsersArray.push(user);
        newUsers.push(user);
      })
      this.$emit('update:users', finalUsersArray)
      this.$emit("users-added", newUsers);
    },

    deleteUser(user) {
      this.$emit('update:users', this.users.filter(val => val.id !== user.id))
      this.$emit('user-deleted', user)
    },

    searchUsers() {
      this.$emit("search", this.search);
    }
  }
}
</script>

<style>

</style>
