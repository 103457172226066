<template>

  <div>

    <page-heading
      heading="Управление группами"
    >
      <span slot="help"></span>
      <div slot="right">
        <el-button
          v-if="$canAndRulesPass(user, $permissions.GROUP.CREATE)"
          type="primary"
          size="small"
          icon="fas fa-plus"
          @click="groupEditModalShow({})"
        >
          Добавить группу
        </el-button>
      </div>
    </page-heading>

    <el-card>

      <el-table
        v-loading="loading"
        size="mini"
        empty-text="Нет групп"
        :data="groups"
      >
        <el-table-column prop="name" label="Название"></el-table-column>
        <el-table-column width="700" align="right">
          <template v-slot="scope">
            <el-button
              v-if="$canAndRulesPass(scope.row, $permissions.GROUP.UPDATE)"
              type="primary"
              plain
              size="mini"
              style=""
              icon="el-icon-user-solid"
              @click="manageUsersModalShow(scope.row.id)"
            >
              Управление пользователями
            </el-button>
            <el-button
              v-if="$canAndRulesPass(scope.row, $permissions.GROUP.UPDATE)"
              class="table-action-button"
              size="mini"
              round
              icon="fas fa-pen"
              @click="groupEditModalShow(scope.row)"
            >
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <paginator
        :paginator.sync="paginator"
        @page-changed="getUserGroups"
      ></paginator>

    </el-card>


    <el-dialog
      title="Редактирование группы"
      :visible.sync="groupEditModal.show"
      width="30%"
    >
      <el-row style="">
        <el-form
          ref="groupForm"
          size="small"
          label-position="top"
          :model="groupEditModal.group"
          :rules="groupEditModal.rules"
        >
          <el-form-item label="Наименование" prop="name">
            <el-input v-model="groupEditModal.group.name"></el-input>
          </el-form-item>
        </el-form>


      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-popconfirm
          v-if="$canAndRulesPass(groupEditModal.group, $permissions.GROUP.DELETE)"
          style="float:left"
          v-show="groupEditModal.group.id"
          confirm-button-text='Да'
          cancel-button-text='нет'
          icon="el-icon-question"
          title="Вы уверены, что хотите удалить группу?"
          @confirm="deleteGroup"
        >
          <el-button
            slot="reference"
            size="small"
            type="danger"
            plain
            icon="fas fa-trash"
            :loading="loading"
          >
            Удалить
          </el-button>
        </el-popconfirm>
        <el-button size="small" @click="groupEditModal.show = false">Отмена</el-button>
        <el-button size="small" type="primary" :loading="loading" @click="saveGroup">Сохранить</el-button>
      </div>
    </el-dialog>


    <el-dialog
      title="Пользователи группы"
      :visible.sync="manageUsersModal.show"
      width="70%"
    >
      <el-row>
        <users-table
          :loading="manageUsersModal.loading"
          :users.sync="manageUsersModal.users"
          :paginator="manageUsersModal.paginator"
          search-field
          @search="manageUsersModalSearchChanged"
          @page-changed="manageUsersModalPageChanged"
          @user-deleted="manageUsersModalUserDeleted"
          @users-added="manageUsersModalUsersAdded"
        ></users-table>
      </el-row>

      <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="manageUsersModal.show = false">Закрыть</el-button>
                </span>
    </el-dialog>
  </div>

</template>

<script>
import PageHeading from "../components/PageHeading";
import UsersTable from "../components/user/UsersTable";
import Paginator from "@/components/Paginator";
import {paginator} from "@/mixins/paginator";
import {mapGetters} from "vuex";

export default {
  name: 'Groups',
  components: {Paginator, UsersTable, PageHeading,},
  mixins: [paginator],

  computed: {
    ...mapGetters(['user']),
  },
  mounted() {
    this.getUserGroups();
  },

  data() {
    return {
      loading: false,
      groups: [],

      groupEditModal: {
        show: false,
        group: {
          id: null,
          name: null,
        },
        rules: {
          name: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        },
      },

      manageUsersModal: {
        show: false,
        loading: false,
        groupId: null,
        search: null,
        page: 1,
        users: [],
        paginator: null,
      },

      manageAdminsModal: {
        show: false,
        loading: false,
        groupId: null,
        search: null,
        page: 1,
        users: [],
        paginator: null,
      },
    }
  },
  methods: {
    getUserGroups() {
      this.loading = true;
      this.$api.group.search({
        expand: 'permissions',
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator}) => {
          this.groups = data.groups;
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    groupEditModalShow(group) {
      if (this.$refs.groupForm) {
        this.$refs.groupForm.resetFields();
      }

      this.groupEditModal.group.id = group.id;
      this.groupEditModal.group.name = group.name;
      this.groupEditModal.group.permissions = group.permissions;
      this.groupEditModal.show = true;
    },

    deleteGroup() {
      this.loading = true;
      this.$api.group.delete({
        id: this.groupEditModal.group.id
      })
        .then((data) => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Группа успешно удалена'
          });
        })
        .finally(() => {
          this.getUserGroups();
          this.groupEditModal.show = false;
        })
    },

    saveGroup() {
      this.loading = true;
      this.$api.group.save(this.groupEditModal.group)
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Группа успешно сохранена'
          });
        })
        .finally(() => {
          this.getUserGroups();
          this.groupEditModal.show = false;
        })
    },


    manageUsersModalShow(groupId) {
      this.manageUsersModal.groupId = groupId;
      this.manageUsersModal.search = null;
      this.manageUsersModal.page = 1;
      this.manageUsersModal.show = true;
      this.manageUsersModal.paginator = null;
      this.manageUsersModal.users = [];

      this.manageUsersModalUsersSearch();
    },
    manageUsersModalUsersSearch() {
      this.manageUsersModal.loading = true;
      this.$api.user.search({
        group_ids: [this.manageUsersModal.groupId],
        search: this.manageUsersModal.search,
        page: this.manageUsersModal.page,
      })
        .then(({data, paginator}) => {
          this.manageUsersModal.users = data.users;
          this.manageUsersModal.paginator = paginator;
        })
        .finally(() => {
          this.manageUsersModal.loading = false;
        })
    },
    manageUsersModalSearchChanged(search) {
      this.manageUsersModal.page = 1;
      this.manageUsersModal.search = search;
      this.manageUsersModalUsersSearch();
    },
    manageUsersModalPageChanged(page) {
      this.manageUsersModal.page = page;
      this.manageUsersModalUsersSearch();
    },

    manageUsersModalUsersAdded(users) {
      let ids = users.map(user => user.id);
      this.manageUsersModal.loading = true;
      this.$api.group.addUsersToGroup({
        group_id: this.manageUsersModal.groupId,
        user_ids: ids
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Пользователи успешно добавлены'
          });
        })
        .finally(() => {
          this.manageUsersModalUsersSearch();
        })
    },
    manageUsersModalUserDeleted(user) {
      this.manageUsersModal.loading = true;
      this.$api.group.deleteUsersFromGroup({
        group_id: this.manageUsersModal.groupId,
        user_ids: [user.id]
      })
        .then(data => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Пользователь успешно удален из группы'
          });
        })
        .finally(() => {
          this.manageUsersModal.loading = false;
        })
    },


  }
}

</script>

<style>

</style>