<template>
  <div v-loading="loading">
    <el-tag
      style="margin: 5px 5px 5px 0"
      :closable="false"
      type="danger"
      v-for="(error, index) in errors"
      :key="index"
    >
      {{ error }}
    </el-tag>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "card-errors",
  components: {},

  props: {
    card: {type: Object, required: true},
  },

  computed: {
    ...mapGetters('card/errors', {
      loading: 'loading',
      errors: 'errors'
    }),
  },
  mounted() {
    this.$store.dispatch('card/errors/loadErrors');
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'card/updateCard') {
        this.$store.dispatch('card/errors/loadErrors');
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  data() {
    return {
      // loading: false,

      // errors: [],
    }
  },

  methods: {}
}
</script>

<style>

</style>