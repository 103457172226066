export default {
    "available_periods": {
        "1": "Месяц",
        "3": "Квартал",
        "6": "Полугодие",
        "12": "Год"
    },
    "available_sections_for_period": {
        "1": {
            "kpi": [],
            "kpi_manual_fact": [],
            "mbo": []
        },
        "3": {
            "kpi": []
        },
        "6": {
            "kpi": [],
            "kpi_manual_fact": [],
            "mbo": []
        },
        "12": {
            "kpi": [],
            "kpi_manual_fact": [],
            "mbo": []
        }
    },
    "medium_facts": {
        "available": true
    },
    "cards_visible_permissions_criteria_map": {
        "card.see_if_participate": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardIParticipateCriteria",
        "card.see_subordinates": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardAllSubordinatesCriteria"
    },
    "cards_page_filters": {
        "template": "componentsCompany/demo/CardsPageFilters",
        "search_fields": {
            "cards.id": {
                "condition": "=",
                "type": "number"
            },
            "users.last_name": {
                "relation": "user"
            },
            "users.first_name": {
                "relation": "user"
            },
            "users.position": {
                "relation": "user"
            }
        }
    },
    "cards_page_columns": [
        {
            "label": "ID",
            "prop": "id",
            "width": 60
        },
        {
            "label": "ФИО",
            "prop": "user.full_name",
            "template": "components/tableColumnTemplates/cards/RouterLinkToCardColumnTemplate"
        },
        {
            "label": "Должность",
            "prop": "user.position"
        },
        {
            "label": "Год",
            "prop": "year"
        },
        {
            "label": "Период",
            "template": "components/tableColumnTemplates/cards/PeriodColumnTemplate"
        },
        {
            "label": "Этап",
            "template": "components/tableColumnTemplates/cards/CurrentStageColumnTemplate",
            "prop": "currentStage.type"
        },
        {
            "label": "Ответственный",
            "template": "components/tableColumnTemplates/cards/CurrentNegotiationColumnTemplate"
        },
        {
            "label": "Индивидуальный результат",
            "prop": "bonus"
        },
        {
            "template": "components/tableColumnTemplates/cards/ActionsColumnTemplate",
            "width": 80,
            "align": "right",
            "on": {
                "delete-card": "(e) => { templateData.$emit(\"delete-card\", e) }"
            }
        }
    ],
    "card_header_elements": [
        {
            "label": "Сотрудник",
            "icon": "fas fa-id-card-alt",
            "prop": "user.full_name"
        },
        {
            "label": "Должность",
            "icon": "fas fa-briefcase",
            "value": "Шикарная должность"
        },
        {
            "label": "Подразделение",
            "icon": "fas fa-building",
            "value": "Лучшее подразделение"
        },
        {
            "label": "Административный руководитель",
            "icon": "far fa-user",
            "prop": "user.chiefs.direct.full_name"
        },
        {
            "label": "Функциональный руководитель",
            "icon": "fas fa-user",
            "prop": "user.chiefs.functional.full_name"
        }
    ],
    "card_page_columns": [
        {
            "label": "Название цели",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        },
        {
            "label": "Вес",
            "width": 90,
            "template": "components/tableColumnTemplates/card/ElementWeightTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Тип цели",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementScaleTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        },
        {
            "label": "Единица измерения",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementUnitTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Шкала выплаты",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementScaleConfigTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий к цели",
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "element": "scope.row",
                "stage": "'plan'",
                "last-comment": "scope.row.last_comment_by_stage['plan']",
                "comments-count": "scope.row.comments_count_by_stage['plan']"
            }
        },
        {
            "label": "Промежуточная оценка",
            "manualColumnTemplate": true,
            "if": "!['plan', 'correction'].includes(templateData.currentStage?.type)",
            "template": "components/tableColumnTemplates/card/mediumFacts/ElementMediumFactsTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        },
        {
            "label": "Факт",
            "if": "!['plan', 'correction'].includes(templateData.currentStage?.type)",
            "template": "components/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Комментарий к факту",
            "if": "!['plan', 'correction'].includes(templateData.currentStage?.type)",
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "element": "scope.row",
                "stage": "'assessment'",
                "last-comment": "scope.row.last_comment_by_stage['fact']",
                "comments-count": "scope.row.comments_count_by_stage['fact']"
            }
        },
        {
            "label": "Взвешенный % выплаты по цели",
            "if": "!['plan', 'correction'].includes(templateData.currentStage?.type)",
            "prop": "bonus",
            "width": 100
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ],
    "card_selectable_page_columns": [
        {
            "label": "Название цели",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        },
        {
            "label": "Тип цели",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementScaleTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        },
        {
            "label": "Единица измерения",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementUnitTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        },
        {
            "label": "Шкала выплаты",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementScaleConfigTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        }
    ]
}