import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        search(payload){
            return requestSender('get', 'user/search', payload);
        },
        getAuthUser(payload){
            return requestSender('get', 'user/auth-user-data', payload);
        },

    }
}