<template>
  <el-dialog
    :title="negotiation.id ? 'Редактирование шага' : 'Создание шага'"
    append-to-body
    :visible.sync="show"
    width="40%"
  >
    <el-form
      v-loading="loading"
      ref="addNegotiationForm"
      size="small"
      label-position="top"
      :model="negotiation"
      :rules="negotiationRules"
    >
      <el-form-item label="Тип" prop="type">
        <el-select v-model="negotiation.type" style="width: 100%">
          <el-option
            v-for="(typeConfig, type) in $companyConfiguration.cardStageNegotiations.getNegotiationTypesConfig()"
            :key="type"
            :label="typeConfig.showName"
            :value="type"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Название" prop="description" v-if="negotiation.type === 'other'">
        <el-input
          v-model="negotiation.description">
        </el-input>
      </el-form-item>

      <el-form-item label="Сотрудник" v-if="negotiation.type === 'other'">
        <el-row type="flex" justify="space-between">
          <div>{{negotiation.user ? negotiation.user.full_name : ''}}</div>
          <div>
            <add-users-dialog
              single-select
              title-text="Выбор сотрудника"
              add-button-text="Нажмите на строку для выбора"
              @users-selected="userSelected"
            >
              <el-button
                slot="reference"
                size="mini"
                icon="fas fa-person-booth"
              >
                Выбрать
              </el-button>
            </add-users-dialog>
          </div>
        </el-row>
      </el-form-item>

    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">Отмена</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        @click="saveNegotiation"
      >Сохранить
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import AddUsersDialog from "@/components/user/AddUsersDialog";
export default {
  name: "negotiation-edit-dialog",
  components: {AddUsersDialog},

  props: {},

  computed: {},
  data() {
    return {
      show: false,
      loading: false,

      negotiation: {
        type: null,
        description: null,
        user: null,
      },
      negotiationRules: {
        type: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      },
    }
  },
  methods: {
    open(negotiation={}) {
      if( Object.keys(negotiation).length){
        this.negotiation = Object.assign({}, negotiation);
      } else {
        this.negotiation = {type: null, user: null}
      }

      this.loading = false;
      if( this.$refs.addNegotiationForm ){
        this.$refs.addNegotiationForm.resetFields();
      }
      this.show = true;
    },
    close() {
      this.show = false;
    },

    userSelected(user){
      this.negotiation.user_id = user.id;
      this.negotiation.user = user;
    },

    saveNegotiation() {
      this.$refs.addNegotiationForm.validate((result)=>{
        if( !result ){
          return;
        }
        this.$emit('negotiation-saved', this.negotiation);
        this.close();
      });
    },

  }
}
</script>

<style>

</style>