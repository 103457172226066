export default {
    "default_negotiations": [
        "self",
        "direct_chief",
        "functional_chief"
    ],
    "types": {
        "plan": {
            "showName": "Постановка целей",
            "order": 1,
            "autostart": true,
            "canManageElementsBeforeDateStart": false,
            "canManageElementsAfterDateEnd": true
        },
        "correction": {
            "showName": "Корректировка целей",
            "order": 2,
            "canBeAddedDynamically": true,
            "hideOnCardCreate": true,
            "date_start": null,
            "date_end": null
        },
        "assessment": {
            "showName": "Подведение итогов",
            "order": 3,
            "canManageElementsBeforeDateStart": false,
            "canManageElementsAfterDateEnd": true
        }
    }
}