import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,

    // withCredentials: true,
    headers: {
        // 'selected-user': localStorage.userId ? localStorage.userId : 1,
        // 'X-CSRF-Token': document.getElementById('_csrf_token').innerHTML,
        // accept: 'application/json',
        // 'Content-Type': 'application/json',
        // 'X-Requested-With': 'XMLHttpRequest'
    }

})

axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('jwt');
    config.headers.Authorization = 'Bearer ' + token;

    return config;
});





// axiosInstance.interceptors.response.use(undefined,
//     error => {
//         // const status = error.response ? error.response.status : false;
//         // const originalRequest = error.config;
//         //
//         // console.log(status);
//         // console.log(originalRequest);
//         //
//         // if( status != 401 ){
//             return Promise.reject(error);
//         // }
//         //
//         // window.location.href = '/login';
//         // this.$router.push({ name: 'Login'})
//
//
//         // if (error.config.url === '/auth/refresh-token') {
//         //     console.log('REDIRECT TO LOGIN');
//         //     store.dispatch("logout").then(() => {
//         //         isRefreshing = false;
//         //     });
//         // }
//         //
//         // if (status === API_STATUS_UNAUTHORIZED) {
//         //
//         //
//         //     if (!isRefreshing) {
//         //         isRefreshing = true;
//         //         console.log('dispatching refresh');
//         //         store.dispatch("refreshToken").then(newToken => {
//         //             isRefreshing = false;
//         //             onRefreshed(newToken);
//         //         }).catch(() => {
//         //             isRefreshing = false;
//         //         });
//         //     }
//         //
//         //     return new Promise(resolve => {
//         //         subscribeTokenRefresh(token => {
//         //             // replace the expired token and retry
//         //             originalRequest.headers["Authorization"] = "Bearer " + token;
//         //             resolve(axios(originalRequest));
//         //         });
//         //     });
//         // }
//         // return Promise.reject(error);
//
//
//     }
// );



export default axiosInstance