<template>
  <div>
    <el-table
      v-loading="loading"
      ref="reportTable"
      id="reportTable"
      :data="reportData"
      style="width: 100%"
    >
      <el-table-column
        v-for="(column, columnIndex) in reportDataColumns"
        :key="columnIndex"
      >
        <template slot="header"  slot-scope="scope">
          <div style="font-weight: bold">{{column.block}}</div>
          <div>{{column.field}}</div>
        </template>
        <template slot-scope="scope">
          {{ scope.row[column.column] }}
        </template>
      </el-table-column>
    </el-table>
    <paginator
      :paginator.sync="paginator"
      @page-changed="loadReportData"
    ></paginator>
  </div>
</template>

<script>


import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator.vue";

export default {
  name: "report-result",
  components: {Paginator},
  mixins: [paginator],

  props: {
    builderFields:{},
    builderRelations:{},
    builderSearch:{},
  },
  computed: {},

  data() {
    return {
      loading: false,
      reportData: [],
      reportDataColumns: [],
    }
  },
  methods: {
    loadReportData(page=null){
      if(page){
        this.paginator.page = page;
      }
      document.getElementById('reportTable').scrollIntoView();
      this.loading = true;
      this.reportData = [];

      return this.$api.report.buildReport({
        selected_fields: this.builderFields,
        relations: this.builderRelations,
        search_fields: this.builderSearch,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator}) => {
          this.reportData = data.data;
          this.reportDataColumns = data.headers;
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },
  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

</style>