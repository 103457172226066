export default {
    "types": {
        "10": {
            "showName": "Разработка"
        },
        "20": {
            "showName": "Согласование ФР"
        },
        "30": {
            "showName": "Согласование НР"
        },
        "40": {
            "showName": "Согласование прочее"
        },
        "50": {
            "showName": "Цели в работе"
        },
        "65": {
            "showName": "Самооценка"
        },
        "70": {
            "showName": "Оценка и согласование ФР"
        },
        "80": {
            "showName": "Оценка и согласование ФР"
        },
        "90": {
            "showName": "Оценка и согласование прочее"
        },
        "200": {
            "showName": "Оценка завершена"
        }
    }
}