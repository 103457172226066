export default {
    "matrix_columns": [
        {
            "label": "Название цели",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        },
        {
            "label": "Вес",
            "width": 90,
            "manualColumnTemplate": true,
            "template": "components/tableColumnTemplates/matrix/MatrixWeightTableColumn",
            "bind": {
                "cards": "templateData.headers.cards"
            }
        },
        {
            "label": "Тип цели",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementScaleTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        },
        {
            "label": "Единица измерения",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementUnitTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        },
        {
            "label": "Шкала выплаты",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/matrix/MatrixScaleConfigTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        },
        {
            "label": "Факт",
            "template": "components/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            }
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/matrix/MatrixActionsTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "align": "'center'",
            "width": 50
        }
    ]
}