<template>
  <div>
    <component
      :is="scaleTemplate"
      :data="row.scale"
    ></component>
  </div>
</template>

<script>

import CardElementScaleEditDialog from "@/components/cards/CardElementsBlock/CardElementScaleEditDialog";

export default {
  name: 'library-element-scale-config-table-column',
  components: {CardElementScaleEditDialog},

  props: {
    row: {type: Object, required: true},
  },
  data() {
    return {}
  },
  computed: {
    scaleTemplate() {
      if (!this.row.scale_type) {
        return null;
      }
      return this.$companyConfiguration.scales.getScaleTemplate(this.row.scale_type, 'libraryView');
    }
  },
  methods: {
    saveElementScaleData(element) {
      this.$api.card.saveElementScaleData({
        element_id: element.id,
        data: element.scale,
      })
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });
        })
        .finally(() => {
          this.$refs.editScaleDialog.close();
          this.$emit('elements-changed');
        })
    },
  }
}

</script>

<style>

</style>
