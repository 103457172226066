<template>
  <el-dialog
    title="Создание карт"
    :visible.sync="show"
    width="40%"
    top="2vh"
  >
    <el-form
      ref="cardEditForm"
      size="small"
      label-position="top"
      :model="card"
      :rules="cardRules"
    >

      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item label="Год" prop="year">
            <el-date-picker
              v-model="card.year"
              type="year"
              value-format="yyyy"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col
          v-show="!(Object.keys($companyConfiguration.card.getAvailablePeriods()).length === 1)"
          :span="7"
        >
          <el-form-item label="Период оценки" prop="period">
            <el-select
              v-model="card.period"
            >
              <el-option
                v-for="(cardPeriodName, cardPeriodLength) in $companyConfiguration.card.getAvailablePeriods()"
                :key="cardPeriodLength"
                :label="cardPeriodName"
                :value="+cardPeriodLength"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          v-show="!(availablePeriodIndexes === 1) || !(Object.keys($companyConfiguration.card.getAvailablePeriods()).length === 1)"
          :span="7"
        >
          <el-form-item label="Номер периода" prop="period_index">
            <el-select
              v-model="card.period_index"
            >
              <el-option
                v-for="index in availablePeriodIndexes"
                :key="index"
                :label="index"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="Этапы">
        <el-row
          v-for="stageType in $companyConfiguration.cardStages.getTypes()"
          v-if="!$companyConfiguration.cardStages.ifStageHideOnCardCreate(stageType)"
          :key="stageType"
          class="card-edit-dialog-stage"
        >
          <el-checkbox
            v-model="stages[stageType].enabled"
            border
          >
            {{ $companyConfiguration.cardStages.getStageShowName(stageType) }}
          </el-checkbox>
          <div
            v-show="stages[stageType].enabled"
            class="card-edit-dialog-stage-content"
          >
            <stage-item
              :stage="stages[stageType]"
              :show-stage-name="false"
              :hide-stage-dates="$companyConfiguration.cardStages.getStageConfig(stageType).hideDates"
              @negotiation-deleted="negotiationDeleted(stages[stageType], $event)"
            >
            </stage-item>
          </div>

        </el-row>
      </el-form-item>


      <el-form-item :label="$companyConfiguration.i18n.t('cards', 'sections_block_name')">
        <div
          v-if="!Object.keys($companyConfiguration.card.getAvailableSectionsByPeriod(card.period)).length"
          class="el-cascader__empty-text"
        >
          {{ $companyConfiguration.i18n.t('cards', 'no_sections_for_period_text') }}
        </div>

          <el-checkbox-group v-model="sections" class="sections-group">
            <el-checkbox
              v-for="(sectionConfig, sectionType) in $companyConfiguration.card.getAvailableSectionsByPeriod(card.period)"
              :key="sectionType"
              :label="sectionType"
              :checked="!!sectionConfig.selected"
              border
            >
              {{$companyConfiguration.cardSections.getSectionShowName(sectionType)}}
            </el-checkbox>
          </el-checkbox-group>
      </el-form-item>

      <component
        :is="cardInfoBlockTemplate"
        :info.sync="card.info"
        :card="card"
      ></component>
    </el-form>



    <users-table
      :add-button="true"
      add-button-text="Выбрать пользователей"
      :delete-button="true"
      :users.sync="usersToAdd"
    ></users-table>




    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">Отмена</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        :disabled="!usersToAdd.length"
        @click="createCards"
      >
        Создать
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { Container, Draggable } from 'vue-dndrop';
import StageItem from "@/components/cards/CardStagesBlock/StageItem";
import UsersTable from "@/components/user/UsersTable";

export default {
  name: "card-edit-dialog",
  components: {UsersTable, StageItem, Container, Draggable},

  props: {},

  computed: {
    availablePeriodIndexes(){
      if( !this.card.period ){
        return 0;
      }
      return (12-this.card.period+1);
    },
    cardInfoBlockTemplate() {
      return this.$companyConfiguration.card.getCardCreateInfoTemplate();
    },
  },
  watch: {
    'card.period': function (){
      this.sections = [];
    }
  },
  data() {
    return {
      show: false,
      loading: false,

      card: {
        year: new Date().getFullYear().toString(),
        period: null,
        period_index: null,
        info: {},
      },
      cardRules: {
        year: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        period: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        period_index: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      },

      sections: [],
      stages: {},
      usersToAdd: [],
    }
  },
  beforeMount() {
    for (const [stageType, stageConfig] of Object.entries(this.$companyConfiguration.cardStages.getTypesConfig())) {

      let defaultNegotiations = [];
      for (const [index, negotiationType] of Object.entries(this.$companyConfiguration.cardStageNegotiations.getNegotiationTypesForStage(stageType))) {
        defaultNegotiations.push({
          type: negotiationType,
          permissions: {
            [this.$permissions.CARD_STAGE_NEGOTIATION.MOVE]: {can: true, rulesPass: true, errors: []},
            [this.$permissions.CARD_STAGE_NEGOTIATION.DELETE]: {can: true, rulesPass: true, errors: []},
          }
        });
      }

      this.$set(this.stages, stageType, {
        enabled: !!stageConfig.selected,
        type: stageType,
        date_start: stageConfig.date_start,
        date_end: stageConfig.date_end,
        order: stageConfig.order,
        permissions: {
          [this.$permissions.CARD_STAGE_NEGOTIATION.CREATE]: {can: true, rulesPass: true, errors: []},
          [this.$permissions.CARD_STAGE.SET_DATE]: {can: true, rulesPass: true, errors: []},
        },
        negotiations: defaultNegotiations,
      })
    }
  },
  mounted() {},
  methods: {
    open() {
      this.usersToAdd = [];
      this.show = true;

      // autofill period and index if we have only one period in system
      if( Object.keys(this.$companyConfiguration.card.getAvailablePeriods()).length === 1 ){
        this.card.period = +Object.keys(this.$companyConfiguration.card.getAvailablePeriods())[0];
        if( this.availablePeriodIndexes === 1 ){
          this.card.period_index = 1;
        }
      }
    },
    close() {
      this.show = false;
    },

    negotiationDeleted(stage, {negotiationIndex, negotiation}){
      stage.negotiations.splice(negotiationIndex, 1);
    },

    createCards() {
      this.$refs.cardEditForm.validate((result)=>{
        if( !result ){
          return;
        }

        this.loading = true;
        let userIds = this.usersToAdd.map(user => user.id);
        let stagesArray = Object.keys(this.stages).map(key => this.stages[key]);
        this.$api.card.createCards({
          year: this.card.year,
          period: this.card.period,
          period_index: this.card.period_index,
          stages: stagesArray.filter(stage => stage.enabled),
          sections: this.sections,
          info: this.card.info,
          user_ids: userIds,
        })
          .then(data => {
            this.$notify.success({
              title: 'Сохранено',
              message: 'Элемент успешно сохранен'
            });
          })
          .finally(()=>{
            this.loading = false;
            this.close();
            this.$emit('card-saved');
          })
      });
    },

  }
}
</script>

<style scoped lang="scss">

.card-edit-dialog-stage:not(:last-child){
  margin-bottom: 20px;
}

.card-edit-dialog-stage-content{
  padding-left:20px;
  padding-top: 20px;

  &-dates{
    margin: 10px 0;

    &-datepicker{
      width:140px;
    }
  }
}

.sections-group {
  label.el-checkbox {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 5px;
    display: block;
  }
}


</style>