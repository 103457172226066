<template>
  <div class="no-user-role">
    <div class="no-user-role-body">
      Отсутствуют права доступа к сервису Целеполагания
    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "user-no-role-overlay",
  components: {},

  methods: {}

}
</script>


<style scoped lang="scss">

.no-user-role {
  height: 80vh;
  position: relative;

  &-body{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

</style>