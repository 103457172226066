import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {

        getCustomChiefsByType(payload){
            return requestSender('get', 'orgstruct/get-custom-chiefs-by-type', payload);
        },
        setChief(payload){
            return requestSender('post', 'orgstruct/set-chief', payload);
        },


        get(payload){
            return requestSender('get', 'orgstruct/get', payload);
        },

    }
}