import Api from "@/api/base";


export default {
    namespaced: true,

    state: {
        loading: false,
        errors: [],
    },
    getters: {
        loading(state) {
            return state.loading
        },
        errors(state) {
            return state.errors;
        },
    },
    actions: {
        loadErrors(ctx, payload) {
            if( !ctx.rootState.card.card.id ){
                return;
            }
            ctx.commit('setLoading', true);
            Api.card.getCardErrors({
                id: ctx.rootState.card.card.id
            })
                .then((data) => {
                    ctx.commit('updateErrors', data.errors);
                })
                .finally(() => {
                    ctx.commit('setLoading', false);
                })

        },

        unloadErrors(ctx){
            ctx.commit('updateErrors', []);
        },

    },

    mutations: {
        setLoading(state, isLoading) {
            state.loading = isLoading;
        },
        updateErrors(state, errors) {
            state.errors = errors;
        },
    }
}