import requestSender from "./base/requestSender";

export default function (axiosInstance) {
    return {

        search(payload) {
            return requestSender('get', 'card/search', payload);
        },
        createCards(payload) {
            return requestSender('post', 'card/create', payload);
        },
        delete(payload) {
            return requestSender('post', 'card/delete', payload);
        },
        restoreCards(payload) {
            return requestSender('post', 'card/restore', payload);
        },
        exportToXls(payload, options) {
            return requestSender('post', 'card/export-to-xls', payload, options);
        },


        getCard(payload) {
            return requestSender('get', 'card/get-card', payload);
        },
        getCardSections(payload) {
            return requestSender('get', 'card/get-card-sections', payload);
        },
        getCardErrors(payload) {
            return requestSender('get', 'card/get-card-errors', payload);
        },
        getCardHistory(payload) {
            return requestSender('get', 'card/get-card-history', payload);
        },
        updateCardInfoField(payload) {
            return requestSender('post', 'card/update-card-info-field', payload);
        },


        getCardSectionElements(payload) {
            return requestSender('get', 'card/get-card-section-elements', payload);
        },

        createElement(payload) {
            return requestSender('post', 'card/create-element', payload);
        },
        createElementsFromLibrary(payload) {
            return requestSender('post', 'card/create-elements-from-library', payload);
        },
        cloneElementsToCard(payload) {
            return requestSender('post', 'card/clone-elements-to-card', payload);
        },
        saveElementPropChange(payload) {
            return requestSender('post', 'card/save-element-prop-change', payload);
        },
        saveElementMediumFact(payload) {
            return requestSender('post', 'card/save-element-medium-fact', payload);
        },
        deleteElement(payload) {
            return requestSender('post', 'card/delete-element', payload);
        },


        saveElementScaleData(payload) {
            return requestSender('post', 'card/save-element-scale-data', payload);
        },




        getCardStages(payload) {
            return requestSender('get', 'card-stage/get-card-stages', payload);
        },
        setStageDates(payload) {
            return requestSender('post', 'card-stage/set-stage-dates', payload);
        },
        setStageNegotiationOrder(payload) {
            return requestSender('post', 'card-stage/set-negotiation-order', payload);
        },
        saveNegotiation(payload) {
            return requestSender('post', 'card-stage/save-negotiation', payload);
        },
        deleteNegotiation(payload) {
            return requestSender('post', 'card-stage/delete-negotiation', payload);
        },
        startDynamicStage(payload) {
            return requestSender('post', 'card-stage/start-dynamic-stage', payload);
        },
        deleteStage(payload) {
            return requestSender('post', 'card-stage/delete-stage', payload);
        },



        agreeCard(payload) {
            return requestSender('post', 'card-stage/agree-card', payload);
        },
        disagreeCard(payload) {
            return requestSender('post', 'card-stage/disagree-card', payload);
        },
        setCurrentNegotiation(payload) {
            return requestSender('post', 'card-stage/set-current-negotiation', payload);
        },
    }
}